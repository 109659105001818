@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  color: black;
  background-color: white;
} */
body {
  color: black;
  background-color: white;
}
.no-scroll {
  overflow: hidden;
}
